<template>
  <div class="scroll-block" ref="block"></div>
  <div ref="scrollText" class="scroll-text p-main center textM text500 font1">Scroll to Dance</div>
  <div
    ref="resetText"
    class="reset-text p-main center textM text500 font1 typo-1"
    @click="scrollToTop"
  >
    Reset
  </div>
</template>

<script setup>
// src/components/play/ScrollBlock1.vue
import { ref, onMounted, onUnmounted } from 'vue'
import anime from 'animejs'

const block = ref(null) // Reference to the animated block
const scrollText = ref(null) // Reference to the "Scroll to Dance" text
const animation = ref(null) // Reference to the Anime.js animation
const resetText = ref(null) // Reference to the "Reset" text
let scrollTimeout = null // Timeout for detecting scroll stop

const handleScroll = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const fadeOutStart = 50 // Start fading at 50px scroll
  const fadeOutEnd = 300 // Fully fade out at 300px scroll

  // Animate the scroll-text visibility
  const opacity = Math.max(0, 1 - (scrollTop - fadeOutStart) / (fadeOutEnd - fadeOutStart))
  const translateY = Math.min(20, (scrollTop / fadeOutEnd) * 20)

  if (scrollText.value) {
    scrollText.value.style.opacity = opacity.toFixed(2)
    scrollText.value.style.filter = `blur(${translateY}px)`
  }

  if (resetText.value) {
    resetText.value.style.opacity = opacity.toFixed(2)
    resetText.value.style.filter = `blur(${translateY}px)`
  }

  // Reset scroll timeout if the user keeps scrolling
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
  }

  // If the user stops scrolling, show the text again after 500ms
  scrollTimeout = setTimeout(() => {
    if (scrollText.value) {
      scrollText.value.style.opacity = '1'
      scrollText.value.style.filter = 'blur(0px)'
    }
    if (resetText.value) {
      resetText.value.style.opacity = '1'
      resetText.value.style.filter = 'blur(0px)'
    }
  }, 200)

  // Animate the block using Anime.js
  const maxScroll = document.documentElement.scrollHeight - window.innerHeight
  const progress = scrollTop / maxScroll

  if (animation.value) {
    animation.value.seek(progress * animation.value.duration)
  }
}

onMounted(() => {
  // Initialize the block animation
  animation.value = anime({
    targets: block.value,
    width: ['20vw', '80vw', '50vw', '100vw', '20vw'], // Width transition
    height: ['20vh', '80vh', '50vh', '100vh', '20vh'], // Height transition
    scale: [1, 4, 0.8, 2, 1], // Scaling
    rotate: ['0deg', '90deg', '180deg', '270deg', '360deg'], // Rotation
    easing: 'easeInOutQuad', // Smooth easing
    duration: 8000, // Full animation duration
    autoplay: false, // Controlled by scroll progress
  })

  // Attach the scroll event listener
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  // Cleanup the scroll event listener and timeout
  window.removeEventListener('scroll', handleScroll)
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
  }
})

//
const scrollToTop = () => {
  // Track the Reset button click in Matomo Tag Manager
  window._mtm = window._mtm || []
  window._mtm.push({
    event: 'HomeResetAnimationClick', // Custom event name
    category: 'Scroll Interaction', // Event category
    action: 'Click', // Event action
    name: 'Animation Reset Button', // Event name
    value: 1, // Optional: numeric value
  })

  const duration = 5000 // Total duration in milliseconds
  const start = window.scrollY // Starting scroll position
  const startTime = performance.now() // Starting time

  const animateScroll = (currentTime) => {
    const elapsed = currentTime - startTime // Time elapsed
    const progress = Math.min(elapsed / duration, 1) // Calculate progress (0 to 1)

    // Smooth easing function (easeInOutQuad)
    const easeInOutQuad =
      progress < 0.5 ? 2 * progress * progress : 1 - Math.pow(-2 * progress + 2, 2) / 2

    const scrollTo = start * (1 - easeInOutQuad) // Calculate new scroll position
    window.scrollTo(0, scrollTo)

    if (progress < 1) {
      requestAnimationFrame(animateScroll) // Continue the animation
    }
  }

  requestAnimationFrame(animateScroll)
}
</script>

<style scoped>
.scroll-block {
  position: fixed;
  top: 25vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6000;
  background-color: var(--color-text-dark);
  mix-blend-mode: exclusion;
  pointer-events: none;
  transition: transform 0.1s ease;
}

.scroll-text {
  position: fixed;
  mix-blend-mode: difference;
  min-width: fit-content;
  color: var(--color-text-dark);
  display: flex;
  flex-wrap: wrap;
  top: 45%;
  left: 0%;
  transform: translate(0%, -45%);
  z-index: 7000;
  opacity: 1;

  transition:
    opacity 0.4s ease,
    filter 0.6s ease;
}

@media (min-width: 1200px) {
  .scroll-block {
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
  }
}

.reset-text {
  position: fixed;
  min-width: fit-content;
  color: #7a7a7a78;
  display: flex;
  flex-wrap: wrap;
  top: 45%;
  right: 0%;
  transform: translate(0%, -45%);
  z-index: 7000;
  opacity: 1;
  transition:
    color 0.2s ease,
    opacity 0.4s ease,
    filter 0.6s ease;
}
.reset-text:hover {
  opacity: 0.5;
}
</style>
