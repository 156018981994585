<template>
  <div class="h-100 w-100 over-y-hide">
    <ScrollBlock1 />
    <ScrollBlock2 />
    <GridTypo2 />
  </div>
</template>

<script setup>
// src/views/HomeView.vue
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import GridTypo2 from '@/components/works/grid/GridTypo2.vue'
import ScrollBlock1 from '@/components/play/ScrollBlock1.vue'
import ScrollBlock2 from '@/components/play/ScrollBlock2.vue'

const route = useRoute()
const status = computed(() => route.query.status)
const { t, locale } = useI18n()

// Head Metadata
const headData = computed(() => ({
  title: `${t('siteName')}`,
  meta: [{ property: 'og:title', content: `${t('siteName')}` }],
}))

useHead(headData)

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
})
</script>
