<template>
  <div class="f-col relative">
    <!-- Site Name -->
    <div
      class="flex align-i-s w-fit"
      :class="{ active: showContent }"
      @click.prevent="toggleContent"
    >
      <h1 class="text-h1-tw m-0 p-0 object-link">
        {{ $t('siteName') }}
      </h1>
    </div>

    <!-- Intro Text with Transition -->
    <transition name="toggle-text">
      <div
        v-if="showContent"
        class="textShadow m-top-8 m-bot-4 w-full text0 text400 c-text-1 line-12"
      >
        <p>
          <span class="font2">{{ $t('siteName') }}</span> {{ $t('IntroText') }}
          {{ $t('ContactText') }}
        </p>
        <div class="f-row align-i-c gap-2 m-top-4 textS text400">
          <CopyrightIcon size="11" stroke-width="2" @click="triggerError" />
          <p>2025</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import CopyrightIcon from '../icons/CopyrightIcon.vue'

const props = defineProps({
  active: Boolean, // Prop indicating if the component is active
})

const { t } = useI18n()
const emit = defineEmits(['toggle'])
const showContent = ref(false)

// Watch for changes to `active` prop
watch(
  () => props.active,
  (newValue) => {
    showContent.value = newValue
  },
  { immediate: true },
)

const toggleContent = () => {
  emit('toggle') // Notify parent about the toggle action
}

// sentry trigger test
function triggerError() {
  throw new Error('Test error for Sentry')
}
</script>
