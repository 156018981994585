<template>
  <div @click="toggleDarkMode">
    <CircleDotIcon size="17" stroke-width="2" class="object-link text-h1-tw2" />
  </div>
</template>

<script setup>
// src/components/extras/ThemeSwitcher.vue
import { useTheme } from '@/composables/useTheme'
import CircleDotIcon from '../icons/CircleDotIcon.vue'

const { toggleDarkMode } = useTheme()
</script>
