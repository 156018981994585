<template>
  <div class="expand-box1 flex p-main w-100 align-i-c" @click="toggleScrollingText">
    <AsteriskIcon class="rotating-icon object-link" :size="40" :stroke-width="1" />
    <div v-if="showText" class="text-container">
      <div
        class="scrolling-text no-white text400 font3"
        :style="{ animationDuration: `${scrollSpeed}s` }"
      >
        <p>
          After exposure to strong light in their sensitivity range, photoreceptors of a given type
          become desensitized. For a few seconds after the light ceases, they will continue to
          signal less strongly than they otherwise would.
          <span class="p-color">Colors</span> observed during that period will appear to lack the
          <span class="p-color">color</span> component detected by the desensitized photoreceptors.
          This effect is responsible for the phenomenon of afterimages, in which the eye may
          continue to see a bright figure after looking away from it, but in a complementary
          <span class="p-color">color</span>. Afterimage effects have also been used by artists,
          including Vincent van Gogh. <span class="p-color">Color</span> constancy: When an artist
          uses a limited <span class="p-color">color</span> palette, the human eye tends to
          compensate by seeing any gray or neutral <span class="p-color">color</span> as the
          <span class="p-color">color</span> which is missing from the
          <span class="p-color">color</span> wheel. For example, in a limited palette consisting of
          red, yellow, black, and white, a mixture of yellow and black will appear as a variety of
          green, a mixture of red and black will appear as a variety of purple, and pure gray will
          appear bluish. The trichromatic theory is strictly true when the visual system is in a
          fixed state of adaptation. In reality, the visual system is constantly adapting to changes
          in the environment and compares the various <span class="p-color">colors</span> in a scene
          to reduce the effects of the illumination. If a scene is illuminated with one light, and
          then with another, as long as the difference between the light sources stays within a
          reasonable range, the <span class="p-color">colors</span> in the scene appear relatively
          constant to us. This was studied by Edwin H. Land in the 1970s and led to his retinex
          theory of <span class="p-color">color</span> constancy. [..] Structural
          <span class="p-color">color</span> is studied in the field of thin-film optics. The most
          ordered or the most changeable structural <span class="p-color">colors</span> are
          iridescent. Structural <span class="p-color">color</span> is responsible for the blues and
          greens of the feathers of many birds (the blue jay, for example), as well as certain
          butterfly wings and beetle shells. Variations in the pattern's spacing often give rise to
          an iridescent effect, as seen in peacock feathers, soap bubbles, films of oil, and mother
          of pearl, because the reflected <span class="p-color">color</span> depends upon the
          viewing angle. [w!]
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
// src/components/play/ExpandBox1.vue
import { ref } from 'vue'
import AsteriskIcon from '../icons/AsteriskIcon.vue'

// State to toggle the visibility of scrolling text
const showText = ref(false)

// State to control the speed of the scrolling text (in seconds)
const scrollSpeed = ref(150) // Default speed is 10 seconds

// Function to toggle scrolling text visibility
function toggleScrollingText() {
  showText.value = !showText.value
}
</script>

<style scoped>
.expand-box1 {
  min-height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 100;
  margin-bottom: 1rem;
}
@media (min-width: 900px) {
  .expand-box1 {
    margin-bottom: 0rem;
  }
}

.rotating-icon {
  min-width: 25px;
  animation: rotate 4s linear infinite;
  background-color: transparent;
}

.text-container {
  margin-left: var(--main-padding);
  overflow: hidden;
}

.scrolling-text {
  line-height: 1.2;
  white-space: nowrap;
  display: inline-block;
  font-size: var(--font-size-M);
  animation: scroll-left linear infinite;
}
@media (min-width: 900px) {
  .scrolling-text {
    font-size: var(--font-size-M);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.p-color {
  font-family: var(--main-font2);
}
</style>
