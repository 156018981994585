// src/composables/useScreenSize.js
import { ref, onMounted, onUnmounted } from 'vue'

export function useScreenSize() {
  const currentBreakpoint = ref('')

  // Define breakpoints
  const breakpoints = {
    S: 600,
    M: 900,
    L: 1200,
    XL: 1600,
    XXL: 2000,
    XXXL: 2400
  }

  const updateBreakpoint = () => {
    const width = window.innerWidth
    if (width >= breakpoints.XXXL) {
      currentBreakpoint.value = 'XXXL'
    } else if (width >= breakpoints.XXL) {
      currentBreakpoint.value = 'XXL'
    } else if (width >= breakpoints.XL) {
      currentBreakpoint.value = 'XL'
    } else if (width >= breakpoints.L) {
      currentBreakpoint.value = 'L'
    } else if (width >= breakpoints.M) {
      currentBreakpoint.value = 'M'
    } else if (width >= breakpoints.S) {
      currentBreakpoint.value = 'S'
    } else {
      currentBreakpoint.value = ''
    }
  }

  onMounted(() => {
    updateBreakpoint()
    window.addEventListener('resize', updateBreakpoint)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateBreakpoint)
  })

  return {
    currentBreakpoint,
    isBreakpoint: (breakpoint) => currentBreakpoint.value === breakpoint,
    atLeast: (breakpoint) => {
      const breakpointOrder = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL']
      const currentIndex = breakpointOrder.indexOf(currentBreakpoint.value)
      const targetIndex = breakpointOrder.indexOf(breakpoint)
      return currentIndex >= targetIndex
    }
  }
}
