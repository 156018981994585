<template>
  <div class="scroll-block" ref="block"></div>
</template>

<script setup>
// src/components/play/ScrollBlock2.vue
import { ref, onMounted, onUnmounted } from 'vue'
import anime from 'animejs'

const block = ref(null) // Reference to the block element
const animation = ref(null) // Reference to the Anime.js animation

// Function to handle scroll events
const handleScroll = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const maxScroll = document.documentElement.scrollHeight - window.innerHeight
  const progress = scrollTop / maxScroll // Calculate scroll progress as a value between 0 and 1

  if (animation.value) {
    animation.value.seek(progress * animation.value.duration) // Sync animation to scroll progress
  }
}

onMounted(() => {
  // Initialize the second animation
  animation.value = anime({
    targets: block.value,
    width: ['10vw', '90vw', '30vw', '100vw', '10vw'], // Different width transitions
    height: ['10vh', '90vh', '30vh', '100vh', '10vh'], // Different height transitions
    scale: [0.5, 2, 1.5, 0.8, 1], // Different scaling behavior
    rotate: ['0deg', '-360deg', '180deg', '90deg', '0deg'], // Reversed rotation
    translateX: ['0%', '50%', '-50%', '25%', '0%'], // Add horizontal movement
    translateY: ['0%', '-25%', '50%', '-10%', '0%'], // Add vertical movement
    easing: 'easeInOutCubic', // Alternate easing for a unique effect
    duration: 6000, // Shorter animation duration
    autoplay: false, // Controlled by scroll progress
  })

  // Attach the scroll event listener
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  // Cleanup the scroll event listener
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped>
.scroll-block {
  position: fixed;
  top: 40vh;
  left: 30%;
  transform: translateX(-30%);
  z-index: 6000;
  background-color: var(--color-text-dark);
  mix-blend-mode: exclusion;
  pointer-events: none;
  transition: transform 0.1s ease;
}

@media (min-width: 1200px) {
  .scroll-block {
    top: 30vh;
    left: 30%;
    transform: translateX(-30%);
  }
}
</style>
