<template>
  <div class="w-100 p-main flex">
    <!-- Large Screen Navigation -->
    <template v-if="atLeast('M')">
      <div class="f-col gap-1 align-i-s just-c-c w-2-3">
        <div class="flex w-50 tr-all-1">
          <TitleLine1
            :active="activeComponent === 'TitleLine1'"
            @toggle="handleToggle('TitleLine1')"
          />
        </div>
        <div class="flex w-50 tr-all-1">
          <InfoLine1
            :active="activeComponent === 'InfoLine1'"
            @toggle="handleToggle('InfoLine1')"
          />
        </div>
      </div>
      <div class="flex just-c-e m-left">
        <ThemeSwitcher class="z-2000 m-0 p-0 pointer" />
      </div>
    </template>

    <!-- Small Screen Navigation -->
    <template v-else>
      <div class="f-row align-i-s just-c-c">
        <div class="flex w-1-3">
          <TitleLine1
            :active="activeComponent === 'TitleLine1'"
            @toggle="handleToggle('TitleLine1')"
          />
        </div>
        <div class="flex w-1-3">
          <InfoLine1
            :active="activeComponent === 'InfoLine1'"
            @toggle="handleToggle('InfoLine1')"
          />
        </div>
        <div class="flex w-1-3 just-c-e">
          <ThemeSwitcher class="z-2000 m-0 p-0 pointer" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
// src/components/MainNavigation.vue
import { ref } from 'vue'
import { useScreenSize } from '@/composables/useScreenSize'
import InfoLine1 from '@/components/global/content/InfoLine1.vue'
import ThemeSwitcher from '@/components/global/extras/ThemeSwitcher.vue'
import TitleLine1 from '../global/content/TitleLine1.vue'

const { atLeast } = useScreenSize()

// State to track the currently active component
const activeComponent = ref(null)

//
const handleToggle = (componentName) => {
  if (atLeast('M')) {
    activeComponent.value = activeComponent.value === componentName ? null : componentName
  } else {
    activeComponent.value = activeComponent.value === componentName ? null : componentName
  }
}
</script>
