<template>
  <div id="app">
    <!-- Fixed Header -->
    <MainNavigation class="header fixed top-0" :key="localeKey" />

    <!-- Main Content -->
    <div class="main">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <!-- Asterisk Footer-->
    <ExpandBox1 class="foot1 fixed bottom-0" />
  </div>
</template>

<script setup>
// src/App.vue
import { ref, watch, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import MainNavigation from '@/components/navigation/MainNavigation.vue'
import ExpandBox1 from './components/global/content/ExpandBox1.vue'

const { locale } = useI18n()
const { t } = useI18n()
const localeKey = ref(locale.value)
const route = useRoute()

//// HEAD
useHead({
  meta: [
    {
      name: 'description',
      content: `${t('siteName')} ${t('IntroText')}`,
    },
    { name: 'theme-color', content: '#111111' },
    {
      name: 'keywords',
      content:
        'tremolo, swiss design, architecture, creative, code, art, javascript, experimental, studio, branding, website, frontend, backend, developer, entwicklung, works, gmbh, tremolo.gmbh, swiss, art, programmierung, agentur, art, tremolo.ch, tremolo design',
    },
    { name: 'author', content: t('siteName') },

    // Open Graph Tags
    {
      property: 'og:description',
      content: `${t('siteName')} ${t('IntroText')}`,
    },
    { property: 'og:image', content: '/social-share-image.png' },
    { property: 'og:url', content: 'https://tremolo.co' },
    { property: 'og:type', content: 'website' },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:locale:alternate', content: 'de_CH' },

    // Mobile App Capabilities
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'application-name', content: t('siteName') },

    // Microsoft Tile Color
    { name: 'msapplication-TileColor', content: '#111111' },
  ],
  link: [
    // Favicons
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },

    // Android Icons
    { rel: 'icon', sizes: '192x192', href: '/android-chrome-192x192.png' },
    { rel: 'icon', sizes: '512x512', href: '/android-chrome-512x512.png' },

    // Sitemap
    { rel: 'sitemap', type: 'application/xml', title: 'Sitemap', href: '/sitemap.xml' },
  ],
})

// Watch for locale changes and update key for MainNavigation
watch(locale, (newLocale) => {
  localeKey.value = newLocale
})
</script>
