// src/composables/useTheme.js
import { ref, onMounted } from 'vue'

export function useTheme() {
  const isDarkMode = ref(false)

  // Apply dark mode class to the document
  function applyDarkMode() {
    if (typeof document !== 'undefined') {
      if (isDarkMode.value) {
        document.documentElement.classList.add('dark-mode')
      } else {
        document.documentElement.classList.remove('dark-mode')
      }
    }
  }

  // Toggle dark mode and save the state in localStorage
  function toggleDarkMode() {
    isDarkMode.value = !isDarkMode.value
    if (typeof window !== 'undefined') {
      localStorage.setItem('darkMode', isDarkMode.value)
    }
    applyDarkMode()
  }

  // Check system preference for dark mode and apply it
  function checkSystemPreference() {
    if (typeof window !== 'undefined') {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
      isDarkMode.value = prefersDarkMode
      applyDarkMode()
    }
  }

  // Load dark mode preference from localStorage
  function loadDarkModeFromStorage() {
    if (typeof window !== 'undefined') {
      const storedDarkMode = localStorage.getItem('darkMode')
      if (storedDarkMode !== null) {
        isDarkMode.value = storedDarkMode === 'true' // Parse boolean from string
      } else {
        // If not found in localStorage, use system preference
        checkSystemPreference()
      }
      applyDarkMode()
    }
  }

  // Set initial state on page load
  onMounted(() => {
    loadDarkModeFromStorage()
  })

  return { isDarkMode, toggleDarkMode }
}
