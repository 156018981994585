// src/main.js
import './assets/main.css'
import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import * as Sentry from '@sentry/vue'
// import VueMatomo from 'vue-matomo'

const app = createApp(App)
const head = createHead()

// Sentry
Sentry.init({
  app,
  dsn: 'https://688142b02a38558274bd664920372177@o4508687543500800.ingest.de.sentry.io/4508693411659856',
  integrations: [],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  release: import.meta.env.VITE_RELEASE,
  environment: import.meta.env.VITE_ENVIRONMENT || 'development',
})

// Matomo
// app.use(VueMatomo, {
//   host: 'https://matomo.tremolo.co/matomo/',
//  siteId: 1,
//  trackerFileName: 'matomo',
//   router, // Automatically track route changes
//   enableLinkTracking: true, // Tracks clicks on links automatically
//   requireConsent: false, // Set to true if you need user consent before tracking
//   trackInitialView: true, // Tracks the initial page view
//   debug: import.meta.env.VITE_ENVIRONMENT !== 'production', // Enables debug logs in non-production environments
// })

// window._paq.push(['trackPageView']) // To track a page view

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(head)

app.mount('#app')
