<template>
  <div class="f-col">
    <div
      class="flex align-i-s just-c-s m-bot w-fit"
      :class="{ active: showContent }"
      @click.prevent="toggleContent"
    >
      <div class="text-h1-tw2 z-2000 pointer">{{ $t('Contact') }}</div>
    </div>

    <transition name="toggle-text">
      <div v-if="showContent" class="f-col gap-1 align-i-s m-top-8 m-bot-2">
        <div class="f-row align-i-s gap-2 text-h1-tw object-link">
          <a href="mailto:hello@tremolo.co" target="_blank" class="">Mail</a>
        </div>

        <div class="f-row align-i-s gap-2 text-h1-tw object-link">
          <a href="https://instagram.com/tremolo__o" class="" target="_blank">Instagram</a>
        </div>

        <div class="f-row align-i-s gap-2 text-h1-tw object-link">
          <a href="https://44d9b1.ch" class="" target="_blank">44d9b1</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
// src/components/global/content/InfoLine1.vue
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  active: Boolean, // Indicates if the component is active
})

const { t } = useI18n()
const emit = defineEmits(['toggle'])
const showContent = ref(props.active)

// Watch for changes to `active` prop
watch(
  () => props.active,
  (newValue) => {
    showContent.value = newValue
  },
  { immediate: true },
)

const toggleContent = () => {
  emit('toggle') // Notify parent about the toggle action
}
</script>
